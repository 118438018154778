<template>
  <div class="RedpackerBankForm-wrapper">
    <form-panel ref="formPanel"
                v-bind="submitConfig"
                :form="form"
                :submitBefore="submitBefore">
      <col2-detail>
        <el-form-item label-width="180px"
                      label="停车场厂商名称"
                      :rules="[{ required: true, message: '请输入停车场厂商名称', trigger: 'blur' }]"
                      prop="manufacturerName">
          <v-input v-model="form.manufacturerName"
                   placeholder="请输入停车场厂商名称"
                   :maxlength="30"
                   :width="width" />
        </el-form-item>
        <el-form-item label-width="180px"
                      label="配置类型"
                      :rules="[{ required: true, message: '请选择配置类型', trigger: 'change' }]"
                      prop="configType">
          <v-select v-model="form.configType"
                    :options="configTypeOps"
                    :width="width" />
        </el-form-item>
        <el-form-item label-width="180px"
                      label="appId"
                      :rules="[{ required: true, message: '请输入appId', trigger: 'blur' }]"
                      prop="appId">
          <v-input v-model="form.appId"
                   placeholder="请输入appId"
                   :maxlength="40"
                   :width="width" />
        </el-form-item>
        <el-form-item label-width="180px"
                      label="appSecret"
                      :rules="[{ required: true, message: '请输入appSecret', trigger: 'blur' }]"
                      prop="appSecret">
          <v-input v-model="form.appSecret"
                   placeholder="请输入appSecret"
                   :maxlength="40"
                   :width="width" />
        </el-form-item>
        <el-form-item label-width="180px"
                      label="私钥"
                      :rules="[{ required: true, message: '请输入私钥', trigger: 'blur' }]"
                      prop="privateKey">
          <v-input v-model="form.privateKey"
                   placeholder="请输入私钥"
                   :maxlength="1000"
                   :width="width" />
        </el-form-item>
        <el-form-item label-width="180px"
                      label="停车信息url"
                      :rules="[{ required: true, message: '请输入停车信息url', trigger: 'blur' }]"
                      prop="pullInfoUrl">
          <v-input v-model="form.pullInfoUrl"
                   placeholder="请输入停车信息url"
                   :maxlength="150"
                   :width="width" />
        </el-form-item>
        <el-form-item label-width="180px"
                      label="停车场系统url"
                      :rules="[{ required: true, message: '请输入停车场系统url', trigger: 'blur' }]"
                      prop="pushInfoUrl">
          <v-input v-model="form.pushInfoUrl"
                   placeholder="请输入停车场系统url"
                   :maxlength="150"
                   :width="width" />
        </el-form-item>
        <el-form-item label-width="180px"
                      label="推送appId"
                      :rules="[{ required: true, message: '请输入推送appId', trigger: 'blur' }]"
                      prop="pushAppId">
          <v-input v-model="form.pushAppId"
                   placeholder="请输入推送appId"
                   :maxlength="40"
                   :width="width" />
        </el-form-item>
        <el-form-item label-width="180px"
                      label="推送token"
                      :rules="[{ required: true, message: '请输入推送token', trigger: 'blur' }]"
                      prop="pushAppSecret">
          <v-input v-model="form.pushAppSecret"
                   placeholder="请输入推送token"
                   :maxlength="40"
                   :width="width" />
        </el-form-item>
        <el-form-item label-width="180px"
                      label="推送key"
                      :rules="[{ required: true, message: '请输入推送key', trigger: 'blur' }]"
                      prop="pushPrivateKey">
          <v-input v-model="form.pushPrivateKey"
                   placeholder="请输入推送key"
                   :maxlength="1000"
                   :width="width" />
        </el-form-item>
        <el-form-item label-width="180px"
                      v-if="form.configType===1"
                      label="富士缴费信息获取接口">
          <v-input v-model="form.offlineUrl"
                   placeholder="请输入富士缴费信息获取接口"
                   :maxlength="150"
                   :width="width" />
        </el-form-item>
      </col2-detail>
    </form-panel>
  </div>
</template>
<script>
import { queryUrl, updateUrl, submitCreateFormURL, getParkingConfigIdHasBindingCount } from './api'
import { configTypeOps } from './map'
import { Col2Detail } from 'components/bussiness'
// import { createHTMLVNode } from 'common/vdom'

export default {
  name: 'parkingManufacturerForm',
  components: {
    Col2Detail
  },
  data () {
    return {
      id: this.$route.query.id,
      width: 200,
      configTypeOps: configTypeOps(),
      form: {
        id: undefined,
        manufacturerName: '',
        configType: 2,
        appId: '',
        appSecret: '',
        privateKey: '',
        pullInfoUrl: '',
        pushInfoUrl: '',
        pushAppId: '',
        pushAppSecret: '',
        pushPrivateKey: '',
        offlineUrl: ''
      },
      submitConfig: {
        // queryUrl: queryUrl,
        submitUrl: submitCreateFormURL,
        // submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
        submitMethod: 'POST'
      }
    }
  },
  computed: {
  },
  mounted () {
    const { id } = this.$route.query
    if (id !== undefined) {
      this.$setBreadcrumb('编辑')
      this.pageType = 1
      this.getData({
        id
      })
    } else {
      this.$setBreadcrumb('新增')
    }
  },
  created () {

  },
  methods: {
    getData (params) {
      this.$axios.get(queryUrl, { params }).then(res => {
        if (res.status === 100) {
          let data = res.data
          this.form = Object.assign({}, data)
        }
      })
    },
    async submitBefore (data) {
      let checkCount = 0
      let result = true
      if (data.id) {
        this.submitConfig.submitUrl = updateUrl
        let res = await this.$axios.get(getParkingConfigIdHasBindingCount, {
          params: {
            parkingConfigId: data.id
          }
        })
        if (res.status === 100) {
          checkCount = res.data || 0
        }
      } else {
        data.id = undefined
      }
      if (data.configType === 2) {
        data.offlineUrl = undefined
      }

      if (checkCount > 0) {
        result = await this.$confirm('此停车场已关联多个道闸设施，编辑后将立即生效，是否继续。')
        if (!result) {
          return false
        }
      }
      return data
    }
  }
}
</script>
