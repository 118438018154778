var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "RedpackerBankForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "180px",
                    label: "停车场厂商名称",
                    rules: [
                      {
                        required: true,
                        message: "请输入停车场厂商名称",
                        trigger: "blur",
                      },
                    ],
                    prop: "manufacturerName",
                  },
                },
                [
                  _c("v-input", {
                    attrs: {
                      placeholder: "请输入停车场厂商名称",
                      maxlength: 30,
                      width: _vm.width,
                    },
                    model: {
                      value: _vm.form.manufacturerName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "manufacturerName", $$v)
                      },
                      expression: "form.manufacturerName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "180px",
                    label: "配置类型",
                    rules: [
                      {
                        required: true,
                        message: "请选择配置类型",
                        trigger: "change",
                      },
                    ],
                    prop: "configType",
                  },
                },
                [
                  _c("v-select", {
                    attrs: { options: _vm.configTypeOps, width: _vm.width },
                    model: {
                      value: _vm.form.configType,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "configType", $$v)
                      },
                      expression: "form.configType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "180px",
                    label: "appId",
                    rules: [
                      {
                        required: true,
                        message: "请输入appId",
                        trigger: "blur",
                      },
                    ],
                    prop: "appId",
                  },
                },
                [
                  _c("v-input", {
                    attrs: {
                      placeholder: "请输入appId",
                      maxlength: 40,
                      width: _vm.width,
                    },
                    model: {
                      value: _vm.form.appId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "appId", $$v)
                      },
                      expression: "form.appId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "180px",
                    label: "appSecret",
                    rules: [
                      {
                        required: true,
                        message: "请输入appSecret",
                        trigger: "blur",
                      },
                    ],
                    prop: "appSecret",
                  },
                },
                [
                  _c("v-input", {
                    attrs: {
                      placeholder: "请输入appSecret",
                      maxlength: 40,
                      width: _vm.width,
                    },
                    model: {
                      value: _vm.form.appSecret,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "appSecret", $$v)
                      },
                      expression: "form.appSecret",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "180px",
                    label: "私钥",
                    rules: [
                      {
                        required: true,
                        message: "请输入私钥",
                        trigger: "blur",
                      },
                    ],
                    prop: "privateKey",
                  },
                },
                [
                  _c("v-input", {
                    attrs: {
                      placeholder: "请输入私钥",
                      maxlength: 1000,
                      width: _vm.width,
                    },
                    model: {
                      value: _vm.form.privateKey,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "privateKey", $$v)
                      },
                      expression: "form.privateKey",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "180px",
                    label: "停车信息url",
                    rules: [
                      {
                        required: true,
                        message: "请输入停车信息url",
                        trigger: "blur",
                      },
                    ],
                    prop: "pullInfoUrl",
                  },
                },
                [
                  _c("v-input", {
                    attrs: {
                      placeholder: "请输入停车信息url",
                      maxlength: 150,
                      width: _vm.width,
                    },
                    model: {
                      value: _vm.form.pullInfoUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "pullInfoUrl", $$v)
                      },
                      expression: "form.pullInfoUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "180px",
                    label: "停车场系统url",
                    rules: [
                      {
                        required: true,
                        message: "请输入停车场系统url",
                        trigger: "blur",
                      },
                    ],
                    prop: "pushInfoUrl",
                  },
                },
                [
                  _c("v-input", {
                    attrs: {
                      placeholder: "请输入停车场系统url",
                      maxlength: 150,
                      width: _vm.width,
                    },
                    model: {
                      value: _vm.form.pushInfoUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "pushInfoUrl", $$v)
                      },
                      expression: "form.pushInfoUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "180px",
                    label: "推送appId",
                    rules: [
                      {
                        required: true,
                        message: "请输入推送appId",
                        trigger: "blur",
                      },
                    ],
                    prop: "pushAppId",
                  },
                },
                [
                  _c("v-input", {
                    attrs: {
                      placeholder: "请输入推送appId",
                      maxlength: 40,
                      width: _vm.width,
                    },
                    model: {
                      value: _vm.form.pushAppId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "pushAppId", $$v)
                      },
                      expression: "form.pushAppId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "180px",
                    label: "推送token",
                    rules: [
                      {
                        required: true,
                        message: "请输入推送token",
                        trigger: "blur",
                      },
                    ],
                    prop: "pushAppSecret",
                  },
                },
                [
                  _c("v-input", {
                    attrs: {
                      placeholder: "请输入推送token",
                      maxlength: 40,
                      width: _vm.width,
                    },
                    model: {
                      value: _vm.form.pushAppSecret,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "pushAppSecret", $$v)
                      },
                      expression: "form.pushAppSecret",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "180px",
                    label: "推送key",
                    rules: [
                      {
                        required: true,
                        message: "请输入推送key",
                        trigger: "blur",
                      },
                    ],
                    prop: "pushPrivateKey",
                  },
                },
                [
                  _c("v-input", {
                    attrs: {
                      placeholder: "请输入推送key",
                      maxlength: 1000,
                      width: _vm.width,
                    },
                    model: {
                      value: _vm.form.pushPrivateKey,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "pushPrivateKey", $$v)
                      },
                      expression: "form.pushPrivateKey",
                    },
                  }),
                ],
                1
              ),
              _vm.form.configType === 1
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "180px",
                        label: "富士缴费信息获取接口",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入富士缴费信息获取接口",
                          maxlength: 150,
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.offlineUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "offlineUrl", $$v)
                          },
                          expression: "form.offlineUrl",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }