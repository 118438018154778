// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}communityParking/config/list`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}communityParking/config/export`
// 新增
const submitCreateFormURL = `${API_CONFIG.butlerBaseURL}communityParking/config/save`
// 详情接口
const queryUrl = `${API_CONFIG.butlerBaseURL}communityParking/config/parkingConfigInfo`
// 编辑详情接口
const updateUrl = `${API_CONFIG.butlerBaseURL}communityParking/config/updateParkingConfigInfo`
// 关闭或开启停车场厂商
const eidtStatus = `${API_CONFIG.butlerBaseURL}communityParking/config/eidtStatus`
// 检测厂商是否已经关联道闸设施
const getParkingConfigIdHasBindingCount = `${API_CONFIG.butlerBaseURL}communityParking/community/getParkingConfigIdHasBindingCount`
export {
  getListURL,
  exportListURL,
  queryUrl,
  updateUrl,
  submitCreateFormURL,
  eidtStatus,
  getParkingConfigIdHasBindingCount
}
