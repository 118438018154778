import { mapHelper } from 'common/utils'

// 状态
const status = [
  {
    text: '正常',
    value: 1
  }, {
    text: '关闭',
    value: 0
  }
]
const configType = [
  {
    text: '绿漫自主规范配置',
    value: 2
  }, {
    text: '富士停车场配置',
    value: 1
  }
]
const communityStatus = [
  {
    text: '正常',
    value: 0
  },
  {
    text: '关闭',
    value: 1
  },
  {
    text: '作废',
    value: 2
  }
]

const { map: statusMap, setOps: statusOps } = mapHelper.setMap(status)
const { map: configTypeMap, setOps: configTypeOps } = mapHelper.setMap(configType)
const { map: communityStatusMap, setOps: communityStatusOps } = mapHelper.setMap(communityStatus)
export {
  statusOps,
  statusMap,
  configTypeMap,
  configTypeOps,
  communityStatusMap,
  communityStatusOps
}
